
export const InfoMessage = (props) => {
    return (
        <div style={{width: 'fit-content', height: 'fit-content', padding: '10px 16px', background: '#efefef', borderRadius: '8px'}}>
            <div style={{display: 'flex'}}>
                <img src="/img/units/info.png" width={16} height={16} />
                <div className="spacing-4" />
                <p className="h10 fc-gray-600">{props.text}</p>
            </div>
        </div>
    )
}

export const ErrorMessage = (props) => {
    return (
        <div style={{width: 'fit-content', height: 'fit-content', padding: '10px 16px', background: '#FDF1E7', borderRadius: '8px'}}>
            <div style={{display: 'flex'}}>
                <img src="/img/units/error.png" width={16} height={16} />
                <div className="spacing-4" />
                <p className="h10" style={{color: '#C85824', whiteSpace: 'pre-wrap'}}>{props.text}</p>
            </div>
        </div>
    )
}

export const ErrorText = (props) => {
    return (
        <div style={{width: 'fit-content', height: 'fit-content', display: 'flex'}}>
            <img src="/img/units/toast-error.png" width={16} height={16} />
            <div className="spacing-4" />
            <p className="h10 fc-warning" style={{whiteSpace: 'pre-wrap'}}>{props.text}</p>
        </div>
    )
}