
export const DefaultButton = (props) => {
    const {buttonText, disabled, onClick} = props;
    return (
        disabled ?
        <button 
            style={{width: '100%', height: '51px', background: '#e1e1e1', borderRadius: '10px', border: 'none', cursor: 'default'}}
            disabled={true}
        >
            <p className="h7 fc-gray-500">{buttonText}</p>
        </button> :
        <button 
            style={{width: '100%', height: '51px', background: '#222', borderRadius: '10px', border: 'none'}}
            onClick={onClick}
        >
            <p className="h7 fc-white">{buttonText}</p>
        </button>
    )
}