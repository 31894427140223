import { useDispatch } from 'react-redux';
import { exportXLSX, importXLSX } from '../../apis/services/create/utils';
import { templateData } from '../../data/template';
import unitsStyle from '../../style/units.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const FileDownload = () => {
    return (
        <div className={unitsStyle.FileTransfer__Download__Background}>
            <div className={unitsStyle.FileTransfer__Download__Left}>
                <img src='/img/units/filetransfer-file.png' width={24} />
                <div className='spacing-16' />
                <p className='h5 fc-gray-600'>template.xlsx</p>
            </div>
            <button 
                className={unitsStyle.FileTransfer__Download__Button}
                onClick={() => {exportXLSX(templateData)}}
            >
                <p className='h6 fc-gray-600'>템플릿 다운로드</p>
            </button>
        </div>
    )
};

export const FileUpload = (props) => {
    const { acceptable, isLoading = false, partnerId } = props;
    
    const [uploaded, setUploaded] = useState(null);
    const [checkState, setCheckState] = useState([false, false]);
    const [uploadedState, setUploadedState] = useState(false);
    const randomId = parseInt(Math.random()*1e9);
    //TODO: register 에서 이메일 쿼리로 받아와야 함
    const [isCreating, setIsCreating] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleXLSXupload = (e) => {
        e.preventDefault();
        console.log('handleXLSXupload func called');
        const selected = e.target.files[0];
        setUploaded(selected);
        if (!checkState[1]) setCheckState([true, false]);
        importXLSX(selected, partnerId, setCheckState, setUploadedState, setIsCreating, dispatch);
    }

    useEffect(() => {
        if (uploadedState) {
            const handleNavigateToChat = () => {
                navigate(`/agent/custom/${encodeURIComponent(partnerId)}`);
            }
            handleNavigateToChat();
        }
    }, [uploadedState])

    return (
        <div className={unitsStyle.FileTransfer__Upload__Container}>
            <div className={unitsStyle.FileTransfer__Upload__Background}>
                <label 
                    htmlFor='uploadedfile' 
                    style={{textAlign: 'center'}}
                >
                    <img 
                        src={isCreating ? '/img/units/spinner_40px.gif' : '/img/units/filetransfer-upload.png'} 
                        width={104} 
                        className='pointer'
                    />
                    <div className='spacing-24' />
                    {
                        isLoading ?
                        <p className='h6 fc-gray-600'>파일 업로드 중이에요</p> :
                        <p className='h6 fc-gray-600'>
                            <span className='h6 fc-prim-800'>버튼을 클릭</span>
                            하여 파일을 업로드 해주세요
                        </p>
                    }
                </label>
                <input 
                    type='file' 
                    id='uploadedfile' 
                    name='uploadedfile' 
                    accept={acceptable} 
                    className='visually-hidden'
                    onChange={(e) => {handleXLSXupload(e)}}
                />
            </div>
            <div className='spacing-16' />
            <p className='h7 fc-gray-600'>*확장자는 {acceptable} 만 가능합니다</p>
        </div>
    )
}