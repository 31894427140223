
export const getInsightList = (rawInsightData) => {
    // user numbers converted to ratio(%)
    const totalUserNums = Object.entries(rawInsightData.user_num).reduce((acc, [key, value]) => {
        return acc + value;
    }, 0);
    const userNumsRatio = Object.entries(rawInsightData.user_num).reduce((userNumsRatio, [key, value]) => {
        userNumsRatio[key] = ((value / totalUserNums) * 100).toFixed(0);
        return userNumsRatio;
    }, {'totalUserNums': totalUserNums}); 

    // scatter coordinates conversion
    const coordinates = {
        recommend: [],
        information: [],
    };
    Object.values(rawInsightData.coordinate.recommend).map(val => coordinates.recommend.push({x: val[0], y: val[1]}));
    Object.values(rawInsightData.coordinate.information).map(val => coordinates.information.push({x: val[0], y: val[1]}));
    
    const areaTitle = ['구체적-많은 대화', '추상적-많은 대화', '추상적-적은 대화', '구체적-적은 대화'];
    const areaTitleKey = ['first', 'second', 'third', 'fourth'];
    const tableData = [];

    areaTitleKey.map((data, idx) => {
        tableData.push({
            'title': areaTitle[idx],
            'contents': rawInsightData.keywords[data],
        })
    })

    return {
        userNums: userNumsRatio,
        coordinates: coordinates,
        tableData: tableData,
    }
}

export const getFeedList = (rawFeedData) => {
    const feedListArr = [];

    rawFeedData.map((data, idx) => {
        feedListArr.push({
            date: data.date,
            feedArr: data.feed,
        })
    })

    return feedListArr
}
