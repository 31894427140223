import { useEffect, useState } from "react";
import { validateInput } from "./Validation";
import signupStyle from '../../style/login.module.css';

export const InputField = (props) => {
    const {name, type, labelText, placeholderText, callbackFunc = () => {}, storeFunc = false, password = null, subText = '', subFunc = () => {}, validation = true} = props;
    const [valRes, setValRes] = useState('');
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        const res = validateInput(name, e.currentTarget.value, password);
        setInputValue(e.currentTarget.value);
        if (validation) setValRes(res);
        if (storeFunc) storeFunc(e.currentTarget.value);
        if (callbackFunc && validation) callbackFunc(res);
    }

    useEffect(() => {
        setInputValue('');
        setValRes('');
        callbackFunc('');
    }, [name])

    return (
        <>
            {
                subText.length === 0 ?
                <label htmlFor={name} className="h11 fc-gray-600">
                        {labelText}
                </label> :
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <label htmlFor={name} className="h11 fc-gray-600">
                        {labelText}
                    </label>
                    <button
                        style={{width: 'fit-content', background: 'none', border: 'none'}} 
                        onClick={subFunc}
                    >
                        <p className="h12 fc-prim-800">{subText}</p>
                    </button>
                </div>
            }
            <div className="spacing-8" />
            <input 
                id={name} name={name} type={type} placeholder={placeholderText}
                className={valRes || valRes === '' ? signupStyle.Signup__Input : signupStyle.Signup__Input__error}
                onChange={(e) => handleInputChange(e)}
                onKeyDown={(e) => {if(!validation) callbackFunc(e.key)}}
                value={inputValue}
            />
            {
                (valRes !== '' && !valRes) &&
                getErrorMessages(name)
            }
        </>
    )
}

const getErrorMessages = (type) => {
    let message;
    switch (type) {
        case 'email':
            message = '올바른 형식의 이메일 주소를 입력해 주세요';
            break;
        case 'password':
            message = '8~16자 이내 영문, 숫자, 특수문자만 사용 가능합니다';
            break;
        case 'passwordConfirm':
            message = '비밀번호가 일치하지 않습니다';
            break;
        default:
            return;
    }

    return (
        <div style={{width: '100%', display: 'flex', marginTop: '6px'}}>
            <img src='/img/units/input-error.png' width={13} height={13} />
            <div className="spacing-4" />
            <p className="h13 fc-warning">{message}</p>
        </div>
    )
}