import axios from "axios";
import { getFormattedDate } from "./utils.js";

export const getInsight = async (date, userId) => {
    let startDate = getFormattedDate(date);
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate = getFormattedDate(endDate);
    try {
        const response = await axios.get(process.env.REACT_APP_GET_INSIGHT_URL, {
            params: {
                "userId": userId,
                "from": startDate,
                "to": endDate,
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error while calling insight API: ${error.message}`);
        throw error;
    }
}

export const getFeed = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_GET_FEED_URL, {
            params: {
                userId: 'dlst',
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getFeed API: ${error.message}`);
        throw error;
    }
}

export const getFeedContent = async (feedId, type, text) => {
    try {
        const response = await axios.get(process.env.REACT_APP_GET_FEED_CONTENT_URL, {
            params: {
                userId: 'dlst',
                feedId: feedId,
                type: type,
                text: text,
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getFeed API: ${error.message}`);
        throw error;
    }
}

export const postPushMessages = async (data) => {
    try {
        const response = await axios.post(process.env.REACT_APP_GET_MESSAGE_URL, {
            "userId": "dlst",
            "feedId": data.feedId,
            "type": data.type,
            "text": data.text, 

            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.data.statusCode === 200) { return true }
        else { return false }
    } catch (error) {
        console.error(`Error while calling push msg API: ${error.message}`);
        throw error;
    }
}