import { useEffect, useState } from "react";
import { fetchChatbotInfo, updateChatbotInfo } from "../../apis/services/create/api";
import { TopAccodian } from "../Units/Accodian"
import { AgentCustom } from "./AgentCreate";
import { WidgetSet } from "./WidgetSet";
import { chatBotInitSettings } from "../../data/unitsData";
import Toast from "../Units/Toast";
import { ConfirmModal } from "../Units/Modal";
import { postScript } from "../../apis/api/auth/api";

const DesignMode = () => {
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const [initSettings, setInitSettings] = useState();
    const [isContentOpen, setIsContentOpen] = useState([true, false]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    const updateSetting = (key, newValue) => {
        setInitSettings((prev) => ({
            ...prev,
            [key]: newValue,
        }));
    }

    const sendQuery = () => {
        postScript(partnerId, chatbotId);
        updateChatbotInfo(partnerId, chatbotId, initSettings)
            .then(() => {
                setIsModalVisible(false);
                setIsToastVisible(true);
            })
        sessionStorage.setItem('set', initSettings);
    }

    useEffect(() => {
        fetchChatbotInfo(partnerId, chatbotId)
            .then(res => {
                if (!res) {
                    res = chatBotInitSettings;
                    updateSetting('profileImg', res.profileImg);
                    updateSetting('name', res.name);
                    updateSetting('colorCode', res.colorCode);
                    updateSetting('greetingMessage', res.greetingMessage);
                    updateSetting('recommendSize', res.recommendSize);
                    updateSetting('imageRatio', res.imageRatio);
                    updateSetting('exceptKeyword', res.exceptKeyword);
                    updateSetting('examples', res.examples);
                    updateSetting('position', res.position);
                } else {
                    updateSetting('profileImg', res.profileImg);
                    updateSetting('name', res.name);
                    updateSetting('colorCode', res.colorCode);
                    updateSetting('greetingMessage', res.greetingMessage);
                    updateSetting('recommendSize', res.recommendSize);
                    updateSetting('imageRatio', res.imageRatio);
                    updateSetting('exceptKeyword', res.exceptKeyword);
                    updateSetting('examples', res.examples);
                    updateSetting('position', res.position);
                }
            })
    }, [])

    const chatProps = {
        initSettings: initSettings,
        updateSetting: updateSetting,
        isContentOpen: isContentOpen[0],
        setIsContentOpen: setIsContentOpen,
    }

    const widgetProps = {
        initSettings: initSettings,
        updateSetting: updateSetting,
        isContentOpen: isContentOpen[1],
        setIsContentOpen: setIsContentOpen,
        setIsToastVisible: setIsToastVisible,
        setIsModalVisible: setIsModalVisible,
    }

    return (
        <>
            {
                isModalVisible &&
                <div style={{width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.4)', position: 'fixed', top: 0, left: 0, zIndex: 999}}>
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <ConfirmModal title={'변경 사항을 쇼핑몰에 적용하시겠습니까?'} subText={'변경 사항을 적용하지 않을 시, 작업 내용이 저장되지 않습니다.'} submitFunc={sendQuery} setIsModalVisible={setIsModalVisible}/>
                    </div>
                </div>
            }
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {
                    isToastVisible &&
                    <div style={{width: '100%', height: 'fit-content', position: 'fixed', bottom: 48, left: 0, zIndex: 999}}>
                        <div style={{width: '100%', height: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Toast status={'success'} message={'변경 사항이 반영되었습니다'} isLinkable={false} />
                        </div>
                    </div>
                }
                <div
                    style={{
                        width: '100%',
                        maxWidth: '1044px',
                    }}
                >
                    <TopAccodian 
                        title={"채팅창 설정"}
                        badgeText={"Step 1"}
                        ContentComp={AgentCustom}
                        compProps={chatProps}
                    />
                    <div className="spacing-32" />
                    <TopAccodian
                        title={"위젯 설정"}
                        badgeText={"Step 2"}
                        ContentComp={WidgetSet}
                        subText={"쇼핑몰에서 사용하실 위젯의 종류를 모두 선택해 주세요"}
                        compProps={widgetProps}
                    />
                </div>
            </div>
        </>
    )
}

export default DesignMode;