import axios from "axios";

export const upsertXLSX = async (formData, setCheckState) => {
    console.log('upsertXLSX func called');
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_UPLOAD_DATA_URL, 
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )
        setCheckState([true, true]);
        return {
            status: true,
            example: response.data.data.example,
        };
    } catch (error) {
        alert(`Error while calling upsertXLSX API: ${error.response.data.message}`);
        console.error(`Error while calling upsertXLSX API: ${error.response.data.message}`);
    }
}

export const convertImg = async (formData) => {
    console.log('convertImg func called');
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_CONVERT_IMG_URL,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )
        return response.data.imageUrl;
    } catch (error) {
        console.error(`Error while calling convertImg API: ${error}`);
    }
}

export const fetchChatbotInfo = async (partnerId, chatbotId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_CHATBOT_URL + `/${partnerId}/${chatbotId}`,
            {
                params: {
                    partnerId: partnerId,
                    chatbotId: chatbotId,
                },
                headers: {}
            }
        )
        return response.data || false;
    } catch (error) {
        console.error(`Error while calling fetchChatbotList API: ${error}`)
    }
}

export const updateChatbotInfo = async (partnerId, chatbotId = '37', settings) => {
    try {
        const response = await axios.put(
            process.env.REACT_APP_CHATBOT_URL + `/${partnerId}/${chatbotId}`,
            {
                chatbotId: chatbotId,
                name: settings.name,
                profileImg: settings.profileImg,
                greetingMessage: settings.greetingMessage,
                colorCode: settings.colorCode,
                recommendSize: settings.recommendSize,
                imageRatio: settings.imageRatio,
                exceptKeyword: settings.exceptKeyword,
                examples: [
                    "싱글몰트 위스키 추천해 줄 수 있어?",
                    "스파클링 와인과 페어링하기 좋은 음식은 무엇일까?",
                    "재고할인 중인 제품 리스트를 알려줘."
                ],
                position: settings.position
            },
            {
                params: {
                    partnerId: partnerId,
                    chatbotId: chatbotId,
                },
                headers: {}
            }
        )
    } catch (error) {
        console.error(`Erro while calling updateChatbotInfo api: ${error}`);
    }
}