import { useEffect, useState } from "react";

const Onboarding = (props) => {
    const {setIsOnboardingEnable} = props;
    const initializeState = sessionStorage.getItem('initializeState');
    const isAnalyticsReady = sessionStorage.getItem('isAnalyticsReady');
    const userAccessLevel = sessionStorage.getItem('l');
    const [buttonText, setButtonText] = useState();

    const imgSrc = (
        Number(initializeState) < 2 ?
        '/img/units/onboarding-step1.svg' :
        (
            Number(userAccessLevel) === 0 ?
            '/img/units/onboarding-step2.svg' :
            '/img/units/onboarding-step3.svg'
        )
    )

    useEffect(() => {
        if (Number(initializeState) < 2) {
            setButtonText('콘솔 둘러보기');
        } else if (Number(initializeState) === 2 && Number(userAccessLevel) < 1) {
            setButtonText('젠투 에이전트 사용하기');
        } else {
            setButtonText('젠투 에이전트 배포하러 가기');
        }
    }, [])

    return (
        <div 
            style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 999, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={()=>{
                setIsOnboardingEnable(false)
                sessionStorage.setItem('onboard', false);
            }}
        >
            <div style={{width: '740px', height: 'fit-content', background: '#fff', borderRadius: '20px'}}>
                <div style={{width: '100%', height: '72px', padding: '24px', borderBottom: '1px solid #e1e1e1', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '100%', height: '100%', display: 'flex'}}>
                        <img src="/img/units/onboarding-title.png" width={24} height={24} />
                        <div className="spacing-8" />
                        <p className="h4 fc-gray-700">젠투 도입 절차 안내</p>
                    </div>
                    <img src="/img/units/close.png" width={19} height={19} style={{cursor: 'pointer'}}/>
                </div>
                <img src={imgSrc} width={740} height={486} />
                <div style={{width: '100%', height: '80px', display: 'flex', justifyContent: 'flex-end', padding: '16px', borderTop: '1px solid #e1e1e1'}}>
                    <button 
                        style={{display: 'flex', width: 'fit-content', height: '48px', border: 'none', borderRadius: '10px', alignItems: 'center', background: '#154cca', padding: '14px 16px'}}
                        onClick={()=>{
                            setIsOnboardingEnable(false)
                            sessionStorage.setItem('onboard', false);
                        }}
                    >
                        <p className="h6 fc-white">{buttonText}</p>
                        <img src="/img/units/arrow-forward.png" width={20} height={20} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Onboarding;