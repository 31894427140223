export const validateInput = (type, value, password) => {
        let exp;
        let res;
        switch (type) {
            case 'email':
                exp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                res = new RegExp(exp).test(value);
                return res;
            case 'password':
                exp = /^[a-zA-Z0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,16}$/;
                res = new RegExp(exp).test(value);
                return res;
            case 'passwordConfirm':
                const passwordVal = password || document.getElementById('signup-input-1')?.value;
                res = passwordVal === value;
                return res;
            case 'accessCode':
                exp = /^[a-zA-Z0-9]{6,6}$/;
                res = new RegExp(exp).test(value);
                return res;
            default:
                res = value.length > 0;
                return false;
        }
}